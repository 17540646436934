.dropdown-create-item {
  display: flex;
  cursor: pointer;
  border-top: 1px solid #FD7E14;
  background: #FFE5D0;
  padding: 10px;
  color: #FD7E14;
  font-weight: 700;
  font-size: 1rem;
}

.required:after {
  content: "*";
  color: #DC3545
}

.profile-picture-wrapper {
  position: relative;
  width: 160px;
  height: 160px;
  overflow: hidden;
  border-radius: 50%;
  background-color: #f1f5fa;
  img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.link:hover{
  text-decoration: underline;
}

.primary-link{
  color: $primary;
  font-weight: 500;
  &.grey-link{
    color: $soft-gray;
  }
}

.fileDetail::-webkit-file-upload-button {
  cursor: pointer;
  font-size: .85rem;
  background-color: #7680ff;
  color: #ffffff;
  // display: flex;
  align-items: center;
  border-radius: 100px;
  border: 0px solid;
  padding: 5px 10px 5px 10px;
  // padding: 10px;
  // padding-right: 1rem;
  &:hover {
    background-color: #515dff;
  }
}

.attached-profpict {
  max-width: 200px;
  height: auto;
  object-fit: inherit;
}

.top-shadow {
  box-shadow: 0 -10px 18px rgba(0,0,0,.07);
}

.points-card-body {
  height: 500px;
  overflow-y: auto;
  scroll-behavior: smooth;
  .list-points-wrapper {
    border-bottom: 1px solid $light-gray;
    padding-bottom: 0.5rem;
    .badge-status {
      color: $primary;
      font-size: 12px !important;
      background-color: $soft-purple;
      font-weight: 500;
    }
    .date-time {
      font-size: 14px;
      color: $soft-gray;
      span {
        color: $light_gray;
      }
    }
    .description-wrapper {
      .label {
        font-size: 14px;
        color: $light-gray;
      }
      .description {
        font-weight: 400;
      }
    }
    .point {
      color: $primary;
      font-weight: 600;
    }
  }
}

.main-pagination {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  li {
    float: left;
    cursor: pointer;
    padding: 6px 0;
    a {
      margin: 0 2px;
      border: unset;
      font-size: 14px;
      color: $soft-gray;
      padding: 6px 10px;
    }
    &.selected {
      a {
        color: $primary;
        background-color: $soft-purple;
        border-color: unset;
        border: unset;
        border-radius: 6px;
        font-weight: bold;
      }
    }
  }
}

.custom-error {
  .invalid-feedback {
    display: block;
  }
}

input:not([type=checkbox]):not([type=radio]) {
  font-size: 12px;
  // height: calc(1.8em + 0.75rem + 3px) !important;
}

input[type="radio"] {
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid var(--purple);
  border-radius: 50%;
  background-clip: content-box;
  padding: 2px;
  margin-top: 0;
}
input[type="radio"]:checked {
  background-color: var(--purple);
}

input[type="radio"]:checked:disabled {
  background-color: #b58df6 !important;
}

input[type="radio"]:disabled {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  border: 1px solid #b58df6 !important;
  border-radius: 50%;
  background-clip: content-box;
  padding: 2px;
}

input.with-suffix {
  border-right: 0px solid;
}

input.with-prefix {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}

.form-control {
  border: 1px solid #DEE2E6;
}

.input-group-text-right {
  font-size: .75rem;
  background-color: #ffffff;
  border: 1px solid #CED4DA;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0px solid;
}

.input-group-text-left {
  font-size: .75rem;
  background-color: #ffffff;
  border: 1px solid #CED4DA;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0px solid;
}

.gmap-wrapper {
  .gmnoprint {
    display: none;
  }
}

.workshop-select {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #CED4DA;
  border-radius: 8px;

  img.arrow {
    padding: 8px;
    border-left: 1px solid #CED4DA;
  }
}

.workshop-select-disabled {
  background-color: #DEE2E6;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #CED4DA;
  border-radius: 8px;

  img.arrow {
    padding: 8px;
    border-left: 1px solid #CED4DA;
  }
}

.btn-add-item {
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 3px 10px 3px 3px;
}

.div-add-item {
  cursor: pointer;
  font-size: .85rem;
  background-color: #7680ff;
  color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 100px;
  // padding: 3px 10px 3px 3px;
  padding: 5px;
  padding-right: 1rem;
  &:hover {
    background-color: #515dff;
  }
}

.booking-service-detail-tabs {
  margin-bottom: 21px;
}
