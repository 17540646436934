.dashboard-card {
    .card {
        border-radius: 10px;
        border: none;
        box-shadow: 2px 2px 7px #d2d4d6;
    }
}

.bg-dark-blue {
    background-color: #002eb4;
    color: #fff;
}
.bg-dark-orange {
    background-color: #fe6400;
    color: #fff;
}
.bg-white {
    background-color: white;
    color: #6b7b9a;
}
.bg-grey {
    background-color: #96989a;
    color: #fff;
}
.bg-black {
    background-color: #0a0a0a;
    color: #fff;
}
.bg-red {
    background-color: #de5242;
    color: #fff;
}
.bg-yellow {
    background-color: #f6c443;
    color: #fff;
}
.bg-green {
    background-color: #54b471;
    color: #fff;
}

.btn-blue {
    background-color: #0d6efd;
    color: #fff;
    border-color: #0d6efd;
}

.btn-red {
    background-color: #dc3545;
    color: #fff;
    border-color: #dc3545;
    &:hover {
        background-color: #c6303e;
        border-color: #dc3545;
    }
}

.abs-row {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 5px;
  background-color: #cae8ca;
  border: 2px solid #4CAF50;
}

[data-column-id="action"].rdt_TableRow {
  position: -webkit-sticky;
  position: sticky;
  right: 0;
  background-color: #002eb4;
}

[column-id="book"].rdt_TableHead {
    position: -webkit-sticky;
    position: sticky;
    left:0;
    height: 100px;
  }

 .btn-round {
    border-radius: 20px;
    border: none;
 }
.grey {
    background-color: rgba(grey, 0.1);
    color: #6b7b9a;
    &:hover {
    background-color: rgba(grey, 0.2);
        color: #6b7b9a;
    }
    &:after {
        background-color: rgba(grey, 0.1);
    }
}
.black {
    background-color: rgba(black, 0.1);
    color: #fff;
    &:hover {
    background-color: rgba(black, 0.2);
        color: #fff;
    }
    &:after {
        background-color: rgba(black, 0.1);
    }
}
.white {
    background-color: rgba(white, 0.2);
    color: #fff;
    &:hover {
    background-color: rgba(white, 0.4);
        color: #fff;
    }
    &:after {
        background-color: rgba(white, 0.3);
    }
}

.hover-blue {
    &:hover {
        color: blue;
    }
}