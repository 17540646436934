.menu-resp {
  display: none;
}

.hamburger-menu {
  font-size: 32px;
}

.gmap-autocomplete-wrapper {
  // display: none;
  position: absolute;
  width: calc(100% - 19px);
  left: 10px;
  right: 10px;
  top: 0px;

  .disabled-input {
    width: 100%;
    height: 38px;
    background-color: #000;
    opacity: 10%;
    position: absolute;
    top: 0;
  }
}

@media screen and (min-width: 1442px) {
  .vertical-line {
    border-left: 0.09rem solid #b7bbc2;
    height: 200px;
    object-fit: unset!important;
    position: absolute!important;
    width: unset!important;
    right: 0;
  }
  .filter-sides {
    justify-content: flex-end;
    display: flex;
  }
  .btn-approve-resp {
    margin: 2px 0 0 5px;
    align-self: flex-start;
  }
  .btn-wide {
    width: 100px;
  }
  .btn-filter-width {
    width: 155px;
  }
}

@media screen and (max-width: 1442px) {
  .vertical-line {
    border-left: 0.09rem solid #b7bbc2;
    height: 200px;
    object-fit: unset!important;
    position: absolute!important;
    width: unset!important;
    right: 0;
  }
  .filter-sides {
    justify-content: flex-end;
    display: flex;
  }
  .btn-approve-resp {
    margin: 2px 0 0 5px;
    align-self: flex-start;
  }
  .btn-wide {
    width: 100px;
  }
  .btn-filter-width {
    width: 115px;
    margin: 2 !important;
  }
}

@media screen and (max-width: 1024px) {
  .topbar {
    .navbar-custom {
      margin-left: 0;
    }
  }

  .left-sidenav {
    position: fixed;
    border-right: 1px solid #d9d9d9;
    box-shadow: 2px 0 3px 0 #d9d9d9;

    .left-sidenav-menu {
      height: auto !important;
    }
  }

  .menu-resp {
    display: block;
  }

  .hidden-resp {
    display: none;
  }

  .filter-sides {
    justify-content: flex-end;
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  .logo-rv {
    display: none;
  }
  .breadcrumb {
    display: flex!important;
  }
  .tab-item{
    width: 100%;
  }
  .section-pagination{
    .section-row-per-page{
        width: 100%!important;
        justify-content: center;
    }
    .section-info-paging{
        width: 100%!important;
        text-align: center!important;
    }
    .section-paging{
        width: 100%!important;
        text-align: center!important;
        margin-top: 10px;
    }
  }
  .vertical-line {
    border-right: 0px transparent white;
    height: 0;
  }
  .filter-sides {
    width: 100%;
    display: block;
  }
  .btn-approve-resp {
    margin: 10px 0 0 0;
  }
}

@media screen and (max-width: 576px) {
  .col-form-label {
    text-align: left;
  }

  .user-car-detail {
    width: 300px;
  }

  .wrapperContent {
    header {
      padding: 5px;

      .form-inline {
        width: 100%;

        .form-group {
          width: 100%;
        }

        .width-250 {
          width: 100% !important;
        }
      }
    }
  }

  .section-action {
    .button-action{
      width: 100%!important;
    }
  }
  .section-filters{
    .text-field-filter{
      width: 100%!important;
    }
    .select-field-filter{
      width: 100%!important;
    }
    .text-field-range-price-filter{
      width: 100%!important;
      .content-range-price{
        width: 100%!important;
        .wrapper-range-price{
          flex-direction: column!important;
        }
      }
    }
    .select-text-field-filter{
      width: 100%!important;
    }
    .react-datepicker-wrapper{
      width: 100%!important;
    }
    .button-reset-filter{
      width: 100%!important;
    }
  }
  .btn-create-responsive {
    width: 100%;
  }
}