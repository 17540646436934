.subscriptions_list_created_at{
    display: flex;
    flex-direction: column;
    color: #6C757D;
    font-size: 10px;
    .date{
        font-weight: 700;
    }
    .time{
        font-weight: 400;
    }
}
.subscriptions_list_customer{
    font-size: 12px;
    color: #212529;
}
.subscriptions_list_package{
    font-weight: 400;
    font-size: 12px;
    color: #828282;
}
.subscriptions_list_time_left{
    font-weight: 600;
    font-size: 12px;
    color: #6C757D;
}
.subscriptions_kredit{
    font-weight: 500;
    font-size: 12px;
    color: #6C757D;
}
.kredit_plus{
    font-weight: 700;
    color: #198754
}
.kredit_minus{
    font-weight: 700;
    color: #DC3545;
}
.field-textarea-status{
    min-height: 90px!important;
}