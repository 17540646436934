.table-car-health{
    margin-bottom: 0px!important;
}
.label-inspections-car-health{
    text-align: left!important;
}
.label-describe-scor-car-health{
    text-align: left!important;
    font-weight: bold;
}
.col-radio-car-health{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.radio-car-health{
    position: unset!important;
    margin-top: 0.3rem!important;
    margin-left: unset!important;
}   

.container-btn-save-car-details{
    text-align: center;
}

.btn-save-car-details{
    text-align: center;
    width: 200px;
}

.icon-trash{
    object-fit: unset!important;
    position: absolute!important;
    cursor: pointer;
    width: unset!important;
    height: 21px!important;
}

.list-inspection-origin-workshop{
    color: #11a5ed;
    ;
}
.box{
    margin-right: -20px!important;
}
