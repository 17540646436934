.icon-quest {
    object-fit: unset!important;
    position: absolute!important;
    cursor: pointer;
    margin: 3px 0  0 -20px;
}

.text-overflow {
    white-space: nowrap; 
    width: 300px; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

.link {
    text-decoration: underline;
}



.promo-date{
    .react-datepicker-wrapper{
        .date-field-input{
            width: 100%;
            height: 38px;
            border: 1px solid #CED4DA;
            border-radius: 4px;
            padding: 8px 8px 8px 8px;
            color: #052C65!important;
            font-weight: 250!important;
            &:hover{
                cursor: pointer;
            }
            &:focus, &.focus, &.filled{
                border: 1px solid #6610F2!important;
            }
            &:disabled{
                background-color: #FFFFFF!important;
                cursor: unset;
            }
            
            &:invalid{
                border: 1px solid #ef4d56!important;
            }
            
        }
    }
    .react-datepicker__input-time-container {
        margin-left: -25px;
        width: max-content;
        .react-datepicker-time__caption {
            display: inline;
            color: transparent;
        }
    }
    .react-datepicker__close-icon{
        &::after{
            background-color: #6610F2;
        }
    }
    .react-datepicker__tab-loop{
        position: absolute;
    }
    .react-datepicker-popper{
        padding-top: 7px;
        .react-datepicker{
            padding-top: 15px;
            background-color: #FFFFFF;
            border: 0.5px solid #6610F2;
            box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12), 0px 18px 88px -4px rgba(24, 39, 75, 0.14);
            border-radius: 6px;
        }
        .react-datepicker__triangle{
            &::after, &::before{
                border: unset;
                content: unset;
            }
        }
        .react-datepicker__navigation{
            top: 14px;
        }
        .react-datepicker__navigation.react-datepicker__navigation--previous{
            left: 0px;
        }
        .react-datepicker__navigation.react-datepicker__navigation--next{
            right: 0px;
        }
        .react-datepicker__month-container{
            padding: 0px 10px 0px 10px
        }
        .react-datepicker__header{
            background-color: #FFFFFF;
            border-bottom: unset;
            padding: 0px;
            .react-datepicker__current-month{
                margin: 0px 10px;
                font-size: 14px;
                font-family: sans-serif;
                letter-spacing: 0.5px;
                color: #212529;
            }
            .react-datepicker__day-names{
                margin-bottom: 5px;
            }
            .react-datepicker__day-name{
                font-weight: 600;
            }
            .react-datepicker__header__dropdown--select{
                padding-bottom: 35px;
                border-bottom: 1px solid #DEE2E6;
                margin-top: 10px;
            }
            .react-datepicker__month-dropdown-container--select{
                position: absolute;
                left: 0;
            }
            .react-datepicker__year-dropdown-container--select{
                position: absolute;
                right: 0;
            }
            .react-datepicker__month-select, .react-datepicker__year-select{
                border: unset;
                color: #6610F2;
                font-weight: 600;
                border: 1px solid #6610F2;
                height: 25px;
                border-radius: 6px;
                cursor: pointer;
            }
            .select-items{
                display: block;
            }
            .select-hide {
                display: none!important;
            }
        }
        .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
            font-family: sans-serif;
            color: #212529;
            font-weight: 500;
            margin: 2px 0px;
            width: 2rem;
            line-height: 2.5rem;
        }
        .react-datepicker__month{
            margin: 0rem 0.4rem 0.4rem 0.4rem;
            .react-datepicker__week{
                .react-datepicker__day{
                    &:hover{
                        color: #212529;
                        background-color: #E0CFFC;
                    }
                }
                .react-datepicker__day--today{
                    font-weight: 700;
                }
                .react-datepicker__day.react-datepicker__day--keyboard-selected {
                    background: none;
                    color: black;
                }
                .react-datepicker__day.react-datepicker__day--selected {
                    border-radius: 6px;
                    color: #FFFFFF;
                    background-color: #6610F2;
                    &:hover{
                        color: #FFFFFF;
                        background-color: #6610F2;
                    }
                }
                .react-datepicker__day.react-datepicker__day--in-range, .react-datepicker__day.react-datepicker__day--in-selecting-range{
                    color: #212529;
                    background-color: #E0CFFC;
                    border-radius: 0px;
                }
                .react-datepicker__day.react-datepicker__day--selecting-range-start, .react-datepicker__day.react-datepicker__day--range-start, .react-datepicker__day.react-datepicker__day--range-end {
                    border-radius: 6px 0px 0px 6px;
                    color: #FFFFFF;
                    background-color: #6610F2;
                    &:hover{
                        color: #FFFFFF;
                        background-color: #6610F2;
                    }
                }
                .react-datepicker__day.react-datepicker__day--range-end {
                    border-radius: 0px 6px 6px 0px;
                }
                .react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--range-end {
                    border-radius: 6px;
                }

                //outside month
                .react-datepicker__day.react-datepicker__day--outside-month{
                    color: #CED4DA;
                    &:hover{
                        background-color: #CED4DA;
                        color: #212529
                    }
                }
                .react-datepicker__day.react-datepicker__day--in-selecting-range.react-datepicker__day--outside-month, .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--outside-month{
                    background-color: #CED4DA;
                    color: #212529;
                }
                .react-datepicker__day.react-datepicker__day--selecting-range-start.react-datepicker__day--outside-month, .react-datepicker__day.react-datepicker__day--range-start.react-datepicker__day--outside-month, .react-datepicker__day.react-datepicker__day--range-end.react-datepicker__day--outside-month {
                    color: #FFFFFF;
                    background-color: #838485;
                    &:hover{
                        color: #FFFFFF;
                        background-color: #838485;
                    }
                }
                //end of outside month
            }
        }
        .react-datepicker__today-button {
            background-color: unset;
            border-top: 1px solid #DEE2E6;
            font-weight: 400;
            font-size: 14px;
            color: #6610F2;
            font-family: sans-serif;
            letter-spacing: 0.5px;
            margin: 0px 10px 0px 10px;
            padding: 5px 0px 8px 0px;
            &:hover{
                font-weight: 600;
            }
        }
    }
}