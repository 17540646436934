$default: #303e67;
$primary: #6610f2;
$base: #e0cffc;
$secondary: #11a5ed;
$info: #9ba7ca;
$light: #f1f5fa;
$border: #eceff5;
$sub_border: #e5eaec;
$light_gray: #ced4da;
$black-lg: #000;
$soft-gray: #999999;
$soft-purple: #E0CFFC;